<template>
  <b-modal
      id="ModalWarehouseCompanies"
      :title="editing ? `Bodega ${formTab.code}` : 'Agregar nueva bodega'"
      :ok-title="editing ? 'Editar bodega' : 'Crear bodega'"
      ok-variant="warning"
      modal-class="dialog-900"
      @close="onClose"
      ok-only
      centered
      :ok-disabled="loading"
      @ok="ok"
      @show="onShow"
      >
    <form-render
        :fields.sync="fields"
        :form.sync="form"
        @send="onAccept"
        :key="keyFormRender"
        ref="formRenderAccount"
        containerButtonsClass="col-sm-12 col-lg-4 container-button"
    >
      <template #space />
      <template #b2b_title>
        <h2 class="card-title">Datos para servicios B2B/B2C</h2>
      </template>
      <template #serviceForCode>
        <div v-for="(codeForService, index) in codeForServices" :key="codeForService.id" class="flex pt-1 border-top mt-1" v-show="showTemplate">
          <div class="col-md-4">
          <label>Nombre Servicio</label>
          <v-select
              id="nameServices"
              class="w-100"
              v-model="codeForService.name"
              :name="`$code_${index}`"
              :closeOnSelect="true"
              :dir="'ltr'"
              label="text"
              :options="optionsForCodeService"
              :placeholder="'Selecciona un servicio'"
              :selectable="(option) => !option.disabled"
          />
          </div>

          <div class="col-md-4">
            <label>Codigo Servicio</label>
            <b-form-input
                :placeholder="'Escriba Codigo Servicio'"
                :name="`code_${index}`"
                v-model="codeForService.code"
                aria-describedby="input-live-help"
            />
          </div>
          <div class="col-sm-2 col-md-1 d-flex align-items-center">
            <b-button v-if="index === 0" variant="outline-light" @click="newServiceForCode()" :disabled="codeForServices.length === optionsForCodeService.length" class="add-schedule-btn"><feather-icon icon="PlusIcon" size="22"/></b-button>
            <b-button v-else variant="outline-danger" @click="deleteServiceForCode(index)" class="delete-schedule-btn"><feather-icon icon="TrashIcon" size="16"/></b-button>
          </div>
        </div>
      </template>
    </form-render>
  </b-modal>
</template>

<script>
import CarriersService from '@/views/modules/configuration/carriers/carriers.service'
import BaseServices from '@/store/services/index'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'


export default {
  name: 'ModalWarehouseCompanies',
  // props: ['formTab', 'companyId', 'noGetData'],
  props: {
    formTab: { type: Object, default: () => ({}) },
    companyId: { type: [Number, String] },
    noGetData: { type: Boolean, default: false },
    afterSuccess: { type: Function, default: null },
    country: { type: Object, default: null }
  },
  components: {
    vSelect
  },
  data() {
    return {
      form: {},
      fields: [],
      fieldFix: [],
      editing: false,
      keyFormRender: 0,
      CarrierService: new CarriersService(this),
      baseService: new BaseServices(this),
      codeForServices: [{}],
      optionsForCodeService: [],
      showTemplate: false,
      dataB2bforEdit: {},
      currentCountry: '',
      optionAddressManual: [
        { id: true, text: ''}
      ],
      isManualAddress: false,
      placesOptions: [],
      selectAdress: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      warehouse_companies: 'getWarehouseCompanies',
      courierCompanies: 'getCourierConfiguration',
      countries: 'getCountriesQuoter'
    })
  },
  watch: {
    courierCompanies() {
      this.setCourierCompany()
    },
    warehouse_companies() {
      this.setZipCodeField()
      this.setInfoCountries()
    },
    countries() {
      this.setInfoCountries()
    },
    formTab() {
      this.initializeForm()
      this.setInfoCountries()
      this.setFieldLocations()
    },
    companyId() {
      if (!!this.companyId) {
        this.$store.dispatch('fetchService', { name: 'getCountriesQuoter' })
      }
    }
  },
  methods: {
    setInitialData() {
      this.fields = [

        { fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-sm-12 col-md-8 container-info', placeholder: 'Escriba nombre de bodega', validation: 'required' },
        { fieldType: 'FieldInput', name: 'code', label: 'Código', containerClass: 'col-sm-12 col-md-4 container-info', placeholder: 'Escriba código de bodega', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'place', label: 'Ubicación (Comuna, Colonia, Municipio ó Distrito)', validation: 'required', containerClass: 'col-md-8  container-info',  clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600, persistSearch: true }, change: this.cleanDependency},

        { fieldType: 'FieldCheckbox', name: 'address_manual', label: 'Dirección manual', containerClass: 'col-sm-12 col-md-3 container-info', placeholder: 'Escriba información adicional', options: this.optionAddressManual, align: 'h', change: this.changeManualAddress },
        { fieldType: 'FieldSelect', name: 'full_address', label: 'Dirección (Calle, número)', validation: 'required', containerClass: 'col-md-6  container-info', clearable: true, dependency: 'place', searchOnType: { fx: this.validateAdress, nChars: 3, persistSearch: true, debounce: 600 }, change: this.selectValideAddress, allowIdSearch: true, specialName: true },
        { fieldType: 'FieldInput', name: 'additional', label: 'Información adicional (Depto, bloque, indicaciones, etc)', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba información adicional' },

        {fieldType: 'FieldInput', name: 'contact_name', label: 'Nombre de contacto', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba nombre de contacto'},
        {fieldType: 'FieldInput', name: 'contact_phone', label: 'Número de contacto', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba número de contacto', validation: 'required'},
        {fieldType: 'FieldInput', name: 'contact_email', label: 'Email de contacto', containerClass: 'col-sm-12 container-info', placeholder: 'Escriba email de contacto', validation: 'email'}
      ]
      if (!!this.companyId) {
        //Settear los datos de B2B
        if (!Object.keys(this.courierCompanies).length) this.$store.dispatch('fetchService', { name: 'getCourierConfiguration', params: {company_id: parseInt(this.companyId)}})
        else this.setCourierCompany()
      }
      this.setZipCodeField()
    },
    getCurrierCompany(value) {
      const params = {
        company_id: parseInt(this.companyId)
      }
      const queryParams = {
        text: value
      }
      return this.baseService.callService('getCourierConfiguration', queryParams, params)
        .then(response => {
          return response.data.map(el => ({...el, text: el.carrier_name, id: el.carrier_id}))
        })
        .catch(error => console.error(error))
      
    },
    setZipCodeField() {
      if (this.warehouse_companies?.country?.id === 2) {
        const placeIndex = this.fields.findIndex((el) => el.name === 'place')
        if (placeIndex === -1) return
        this.fields[placeIndex].label = 'Ubicación (Comuna, Colonia, Municipio ó Distrito, Zipcode)'
      }
    },
    changeManualAddress(form, value) {
      const isEditing = !!this.formTab?.id
      const fieldFullAddress = this.fields.find(field => field.name === 'full_address')
      if (isEditing) {
        const fieldLatitud = this.fields.find(field => field.name === 'latitud')
        const fieldLongitud = this.fields.find(field => field.name === 'longitud')
        if (value) {
          fieldLatitud.disabled = false
          fieldLongitud.disabled = false
        } else {
          fieldLatitud.disabled = true
          fieldLongitud.disabled = true
        }
      }

      if (value) {
        fieldFullAddress.fieldType = 'FieldInput'
        this.selectAdress = this.form.full_address
        this.form.full_address = isEditing ? this.formTab.address.full_address : this.form?.full_address?.name || ''
        this.keyFormRender++
        this.isManualAddress = true
      } else {
        fieldFullAddress.fieldType = 'FieldSelect'
        this.form.full_address = this.selectAdress //Matener el objeto con todos los datos en caso q se marque manual y luego se desmarque
        fieldFullAddress.searchOnType = { fx: this.validateAdress, nChars: 3, persistSearch: true, debounce: 200 }
        this.keyFormRender++
        this.isManualAddress = false
      }
    },
    selectValideAddress(form, value) {
      if (!this.isManualAddress) {
        this.form.latitud = value?.geometry?.location?.lat || null
        this.form.longitud = value?.geometry?.location?.lng || null
      }
      if (value?.isRequired) this.setValidations(value.isRequired)
    },
    setInfoCountries() {
      if (!this.countries.rows) return
      this.currentCountry = this.country ?? this.countries?.rows.find(el => el.id === this.warehouse_companies?.country?.id)
    },
    setFieldLocations () {
      if (!!this.formTab?.id) {
        this.fields.splice(
          this.fields.findIndex((el) => el.name === 'additional') + 1,
          0,          
          { fieldType: 'FieldInput', name: 'latitud', label: 'Latitud', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Latitud de direccion', disabled: true },
          { fieldType: 'FieldInput', name: 'longitud', label: 'Logintud', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Latitud de dirección', disabled: true }
        )
      }
    },
    setCourierCompany() {
      const b2bindex = this.fields.findIndex((el) => el.name === 'b2b_title')
      if (this.courierCompanies.length > 0 && ['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        if (b2bindex === -1) {
          this.fields.push(
            { name: 'b2b_title', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1' },
            { fieldType: 'FieldSelect', name: 'carrier_name', label: 'Courier', containerClass: 'col-sm-12 container-info col-md-9', change: this.selectCourier, placeholder: 'Selecciona courier', searchOnType: { fx: this.getCurrierCompany, nChars: 3, debounce: 600, persistSearch: true }  },
            { fieldType: 'FieldCheckbox', name: 'isB2b', label: 'Escoja', options: [{ id: true, text: 'Utiliza Servicio B2B' }], containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', dependency: 'carrier_name'},
            {fieldType: 'FieldInput', name: 'b2b_code', label: 'Código B2B', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba código B2B', dependency: 'isB2b'},
            {fieldType: 'FieldCheckbox', name:'isB2c', label: 'Escoja', options: [{ id: true, text: 'Utiliza Servicio B2C' }], containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', dependency: 'carrier_name'},
            {fieldType: 'FieldInput', name: 'b2c_code', label: 'Código B2C', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Escriba código B2C', dependency: 'isB2c'},
            {fieldType: 'FieldCheckbox', name: 'isCodeForService', label: 'Escoja', options: [{ id: true, text: 'Utiliza código por servicio' }], change: this.showCodeForService, containerClass: 'col-sm-12 col-md-6 container-info', align: 'h', dependency: 'carrier_name'},
            { name: 'serviceForCode', useSlot: true, containerClass: 'col-sm-12 container-info', dependency: 'isCodeForService' }
          )
        }        
        this.setCourier(this.courierCompanies.map(value => ({...value, text : value.carrier_name, id : value.carrier_code})))
      }
    },
    async setPlaces(value) {     
      const params = { country_id: this.currentCountry?.id }
      const queryParams = { str: value }
      return this.baseService.callService('getFullPlaces', queryParams, params)
        .then(resp => { 
          this.placesOptions = resp.places.map(el => ({...el, text: el.full_name, valid: true}))
          return this.placesOptions
        })
        .catch(err => { return err })
    },
    cleanDependency() {
      this.form.full_address = null
      this.fields[this.fields.findIndex((el) => el.name === 'full_address')].options = []
      this.fields[this.fields.findIndex((el) => el.name === 'place')].options = this.placesOptions
      this.keyFormRender++
    },
    setCourier(value) {
      const carrierField = this.fields.find(field => field.name === 'carrier_name')
      carrierField.options = value
    },
    async validateAdress(value) {

      const queryParams = {
        address: `${value}, ${this.form.place.name}, ${this.currentCountry.text}`,
        country_id : this.currentCountry.id
      }

      try {
        const call = await this.baseService.callService('getGoogleAddress', queryParams)

        if (call.data.length === 0) {
          return null
        }
        const response = call.data.map(address => (
          {
            ...address,
            name: address.formatted_address,
            text: this.getFullAddress(address),
            valid: true,
            id: address.place_id,
            isRequired: this.filterComponents(address).every(component => component.types.includes('route')),
            latitude: address.geometry?.location?.lat,
            longitude: address.geometry?.location?.lng
          }
        ))
        return response
      } catch (error) {
        console.error(error)
        this.$alert('No se encontraron resultados para la dirección ingresada')
      }
    },
    getFullAddress(address) {
      let formattedAddress = address.name || address.formatted_address
      const filteredAddressComponents = this.filterComponents(address)
      if (filteredAddressComponents.length > 0) {  
        // Obtén el índice del tipo 'route' en filteredAddressComponents
        const routeIndex = filteredAddressComponents.findIndex(component => component.types.includes('route'))
        // Mueve el tipo 'route' al principio si se encuentra en la lista
        if (routeIndex !== -1) {
        // Elimina el elemento en routeIndex y agrégalo al principio del array
          filteredAddressComponents.unshift(filteredAddressComponents.splice(routeIndex, 1)[0])
        }
        // Ahora, crea el array de shortNames
        const shortNames = filteredAddressComponents.map(component => component.short_name)
        // Unir los short_names en un solo valor
        const combinedValue = shortNames.join(' ')
        // Mostrar el resultado
        formattedAddress = combinedValue
      }
      return formattedAddress
    },
    filterComponents (address) {
      return address.address_components.filter(component => component.types.includes('street_number') || component.types.includes('route'))
    },
    setValidations(isRequired) {
      if (isRequired) this.fields[this.fields.findIndex((el) => el.name === 'additional')].validation = 'required'
      else delete this.fields[this.fields.findIndex((el) => el.name === 'additional')].validation
      this.keyFormRender++
    },
    initializeForm() {
      this.editing = !!this.formTab?.id
      if (this.editing) {
        this.updateFormWithTabData()
      } else {
        this.form = {
          ...this.formTab
        }
      }
    },
    showCodeForService(form, value) {
      this.showTemplate = value
    },
    newServiceForCode() {
      this.codeForServices.push({})
    },
    deleteServiceForCode(index) {
      this.codeForServices.splice(index, 1)
    },
    updateFormWithTabData() {
      this.form = {
        ...this.form,
        ...this.formTab,
        full_address: this.formTab.address.full_address,
        zip_code: this.formTab.address.zip_code,
        latitud: this.formTab.address.latitude,
        longitud: this.formTab.address.longitude
      }
      if (this.warehouse_companies?.country?.id === 2) this.form.place = `${this.formTab.address.place_name}, CP ${this.formTab.address.zip_code || 'No ingresado'}`
      const queryParams = {
        master_warehouse_id: this.formTab.id
      }
      this.baseService.callService('getWarehouseShipper', queryParams)
        .then(response => {
          //{ carrier, code, ...rest }
          const data = response.data[0] || []
          this.dataB2bforEdit = data
          this.form = {
            ...this.form,
            carrier_name: this.courierCompanies.filter(el => el.carrier_code === data.carrier?.code).map(el => ({...el, text: el.carrier_name, id: el.carrier_code}))[0] || data.carrier ? {...data.carrier, carrier_code: data.carrier?.code, text: data.carrier?.name } : null
          }
          this.selectCourier('carrier_name', this.form.carrier_name)
          if (data?.code) {
            this.formatEditB2b(data.code)
          }
        })
    },
    formatEditB2b(code) {
      if (code.b2b) {
        this.form.isB2b = true
        this.form.b2b_code = code.b2b
      }

      if (code.b2c) {
        this.form.isB2c = true
        this.form.b2c_code = code.b2c
      }

      if (Object.keys(code).some(key => key !== 'b2b' && key !== 'b2c')) {
        this.form.isCodeForService = true
        this.showCodeForService('isCodeForService', this.form.isCodeForService)
        this.codeForServices = []
        Object.keys(code).forEach(key => {
          if (key !== 'b2b' && key !== 'b2c') {
            this.codeForServices.push({name: this.optionsForCodeService.filter(el => el.code === key).map(value => ({...value, text: value.name}))[0], code: code[key]})
          }
        })
      }

    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderAccount.checkForm()
    },
    onAccept(form) {
      this.loading = true
      const { carrier_name, name, code, contact_name, contact_phone, contact_email, full_address, place, ...rest } = form
      const queryParams = {
        name,
        code,
        contact_name,
        contact_phone,
        contact_email,
        address_id: full_address?.place_id || this.formTab?.address?.id || null,
        activated: this.formTab?.activated || (this.companyId && 1),
        latitude: this.isManualAddress ? parseFloat(form.latitud) : full_address.latitude,
        longitude: this.isManualAddress ? parseFloat(form.longitud) : full_address.longitude
      }

      const params = {
        company_id : parseInt(this.companyId)
      }
      const cacheParams = {
        company_id: parseInt(this.companyId),
        warehouse_code:this.formTab.code
      }
      if (this.editing) {
        params.warehouse_id = this.formTab.id
        queryParams.address = {
          id: form.full_address?.code || this.formTab.address?.id || null,
          full_address: this.isManualAddress ?  full_address : (full_address.text || this.formTab.address.full_address),
          place_id: form.place?.id || this.formTab.address?.place_id || null,
          information: form.additional || this.formTab.address.additional || null,
          latitude: (this.isManualAddress || !full_address.latitude) ? parseFloat(form.latitud) : full_address.latitude,
          longitude: (this.isManualAddress || !full_address.longitude) ? parseFloat(form.longitud) : full_address.longitude
        }
        queryParams.raw_address = this.isManualAddress ? null : full_address.name || this.formTab.raw_address

        if (this.warehouse_companies?.country?.id === 2) queryParams.address.zip_code = place.code ? place.code : this.formTab.address.zip_code || null
        this.$store.dispatch('fetchService', { name: 'updateWarehouseCompanies', queryParams, params, onSuccess: this.successCreateWarehouse })
        this.$store.dispatch('fetchService', { name: 'warehouseCacheClear', queryParams, cacheParams, onSuccess: () => {} })
      } else {
        queryParams.full_address = this.isManualAddress ? full_address :  full_address.text
        queryParams.place_id = place.id
        queryParams.information = rest.additional
        queryParams.raw_address = this.isManualAddress ? null : full_address.name
        if (this.warehouse_companies?.country?.id === 2) queryParams.zip_code = place.code || null
        this.$store.dispatch('fetchService', { name: 'createWarehouseCompanies', queryParams, params, onSuccess: this.successCreateWarehouse })
      }
    },
    selectCourier(form, value) {
      this.optionsForCodeService = value?.services?.map(el => ({...el, text: el.name, id: el.code})) || []
    },
    getFormDataService(data) {
      const code = {}

      if (data.isB2b) {
        code.b2b = data.b2b_code
      }
      if (data.isB2c) {
        code.b2c = data.b2c_code
      }

      if (data.isCodeForService) {
        Object.keys(this.codeForServices).forEach(key => {
          code[this.codeForServices[key].name.code] = this.codeForServices[key].code
        })

      }

      return {
        ...code
      }

    },
    successCreateWarehouse(data) {
      if (!this.form.isB2b && !this.form.isB2c && !this.form.isCodeForService) {
        return this.succesCreateWarehouseShipper()
      }
      const code = this.getFormDataService(this.form)
      const queryParams = {
        carrier_code: this.form.carrier_name.carrier_code,
        master_warehouse_id: data.warehouse.id,
        code
      }

      if (this.editing && this.dataB2bforEdit.id) {
        const params = {
          b2bId: this.dataB2bforEdit.id
        }
        return this.$store.dispatch('fetchService', { name: 'updateWarehouseShipper', queryParams, params, onSuccess: this.succesCreateWarehouseShipper, onError: this.loading = false })
      }

      this.$store.dispatch('fetchService', { name: 'createWarehouseShipper', queryParams, onSuccess: this.succesCreateWarehouseShipper, onError: this.loading = false })
    },
    succesCreateWarehouseShipper() {
      this.loading = false
      this.$success(this.$t('msg-exito-guardar'))
      this.$bvModal.hide('ModalWarehouseCompanies')
      this.onClose()
      const queryParams = {
      }
      const params = {
        company_id : parseInt(this.companyId)
      }

      this.$store.dispatch('fetchService', { name: 'getWarehouseCompanies', queryParams, params })
      if (this.afterSuccess) this.afterSuccess()
    },
    onClose() {
      this.form = {}
      this.editing = false
      this.keyFormRender++
      this.codeForServices = [{}]
      this.showTemplate = false
      this.isManualAddress = false
    },
    onShow() {
      //Settear los datos de B2B
      this.setInitialData()
      // if (!Object.keys(this.courierCompanies).length) this.$store.dispatch('fetchService', { name: 'getCourierConfiguration', params: {company_id: parseInt(this.companyId)}})
      // else this.setCourierCompany()

      // this.setZipCodeField()
    }
  }
}
</script>


<style scoped lang="scss">

</style>
